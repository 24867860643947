<template>
  <div class="supplierEntryGuidelines_box">
    <!--头部区域-->
    <div class="header_box">
      <div class="header_content">
        <div class="flex align-items-end">
          <a class="logo-box" :href="toIndex" target="_blank">
            <img :src="logoUrl" class="cursor" style="vertical-align: middle" width="128" height="22" :alt="$t('key1000010')">
          </a>
          <h2 class="title">{{ $t('key1005265') }}</h2>
        </div>
        <a class="immediateEntry_btn" :href="supplierSettlementUrl" target="_blank">{{ $t('key1005266') }}</a>
      </div>
    </div>
    <!--主体内容-->
    <div class="supplierEntryGuidelines_content">
      <!--欢迎加入YMS分销平台-->
      <div class="supplierEntryGuidelines_banner">
        <div class="supplierEntryGuidelines_banner_cneter">
          <h2 class="title">{{ $t('key1005267') }}</h2>
          <p class="text">
            {{ $t('key1005268') }}<br/>
            {{ $t('key1005269') }}<br/>
            {{ $t('key1005270') }}
          </p>
        </div>
      </div>
      <!--我们的优势-->
      <div class="ourAdvantages_box">
        <div class="title_box">
          <h2 class="title">{{ $t('key1005271') }}</h2>
          <p class="text">Our Strengths</p>
        </div>
        <div class="ourAdvantages_center">
          <div class="ourAdvantages_center_item" v-for="item in ourAdvantagesList">
            <span :class="languagesVal === 'CN' ? 'icon_title' : 'set_icon_title'">{{ item.iconTitle }}</span>
            <h2 class="title">{{ item.title }}</h2>
            <p class="text">{{ item.text }}</p>
          </div>
        </div>
      </div>
      <!--招商品类-->
      <div class="categoriesInvestment_box">
        <div class="title_box">
          <h2 class="title">{{ $t('key1005272') }}</h2>
          <p class="text">Categories Investment</p>
        </div>
        <div class="categoriesInvestment_center">
          <div class="categoriesInvestment_center_item" v-for="item in categoriesInvestmentlist">
            <img :src="item.img" width="200" height="200" alt="">
            <p class="bottom_box">{{ item.name }}</p>
          </div>
        </div>
      </div>
      <!--供应商资质要求-->
      <div class="qualificationRequirement_box">
        <div class="title_box">
          <h2 class="title">{{ $t('key1005273') }}</h2>
          <p class="text">Qualification Requirement</p>
        </div>
        <div class="flex align-items-start mt30">
          <div class="qualificationRequirement_center mr10">
            <h2 class="title">{{ $t('key1005274') }}</h2>
            <table class="table_box">
              <tr>
                <th>{{ $t('key1005275') }}</th>
                <th>{{ $t('key1005276') }}</th>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('key1002034') }}</td>
                <td>
                  <p>• {{ $t('key1005277') }}</p>
                  <p>• {{ $t('key1005278') }}<span class="pl11">{{ $t('key1005279') }}</span></p>
                  <p>• {{ $t('key1005280') }}</p>
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('key1005281') }}</td>
                <td>
                  <p>• {{ $t('key1005282') }}</p>
                  <p>• {{ $t('key1005283') }}</p>
                </td>
              </tr>
              <tr>
                <td>{{ $t('key1002032') }}</td>
                <td>
                  <p>• {{ $t('key1005284') }}</p>
                </td>
              </tr>
            </table>
          </div>
          <div class="qualificationRequirement_center ml10">
            <h2 class="title">{{ $t('key1005285') }}</h2>
            <table class="table_box">
              <tr>
                <th>{{ $t('key1005286') }}</th>
                <th>{{ $t('key1005285') }}</th>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('key1005287') }}</td>
                <td>
                  <p>• {{ $t('key1005288') }}<span class="pl11">{{ $t('key1005289') }}</span></p>
                  <p>• {{ $t('key1005290') }}</p>
                  <p>• {{ $t('key1005291') }}</p>
                  <p>• {{ $t('key1005292') }}</p>
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('key1005293') }}</td>
                <td>
                  <p>• {{ $t('key1005294') }}</p>
                  <p>• {{ $t('key1005295') }}</p>
                </td>
              </tr>
              <tr>
                <td>{{ $t('key1005296') }}</td>
                <td>
                  <p>• {{ $t('key1005297') }}</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!--入驻流程-->
      <div class="entryProcess_box">
        <div class="title_box">
          <h2 class="title">{{ $t('key1005298') }}</h2>
          <p class="text">Entry Process</p>
        </div>
        <div class="entryProcess_center">
          <div class="entryProcess_center_item" v-for="item in entryProcessList">
            <span class="iconItem">{{ item.index }}</span>
            <h2 class="title">{{ item.title }}</h2>
            <p class="text">{{ item.text }}</p>
          </div>
        </div>
      </div>
      <!--底部banner-->
      <div class="footer_banner">
        <div class="footer_banner_img">
          <h3 class="title_1">{{ $t('key1005299') }}</h3>
          <h2 class="title_2">{{ $t('key1005300') }}</h2>
          <a class="footer_banner_btn" :href="supplierSettlementUrl" target="_blank">{{ $t('key1005266') }}</a>
        </div>
      </div>
    </div>
    <!--底部区域-->
    <shop-footer :footerLogo="false"></shop-footer>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import shopFooter from "@/components/layout/shopping/footer.vue";
import {getHrefPath} from "@/utils/common";

export default {
  name: "supplierEntryGuidelines",
  components: {shopFooter},
  data() {
    let v = this;
    return {
      logoUrl: require('@/assets/images/logo.png'),
      supplierEntryGuidelinesBanner: require('@/assets/images/supplierEntryGuidelines_banner.png'),
      ourAdvantagesList: [
        {iconTitle: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005301'), title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005302'), text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005303')},
        {iconTitle: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005304'), title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005305'), text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005306')},
        {iconTitle: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005307'), title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005308'), text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005309')},
        {iconTitle: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005310'), title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005311'), text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005312')},
        {iconTitle: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000052'), title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005313'), text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005314')},
      ],
      categoriesInvestmentlist: [
        {name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005315'), img: require('@/assets/images/supplierEntryGuidelines_01.png')},
        {name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005316'), img: require('@/assets/images/supplierEntryGuidelines_02.png')},
        {name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005317'), img: require('@/assets/images/supplierEntryGuidelines_03.png')},
        {name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005318'), img: require('@/assets/images/supplierEntryGuidelines_04.png')},
        {name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005319'), img: require('@/assets/images/supplierEntryGuidelines_05.png')},

      ],
      entryProcessList: [
        {index: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005320'), text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005321')},
        {index: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005322'), text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005323')},
        {index: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005324'), text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005325')},
        {index: 4, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005218'), text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005326')},
        {index: 5, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005327'), text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005328')}
      ]
    }
  },
  computed: {
    // 跳转到商城首页
    toIndex() {
      let url = '/index.html#/index';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 供应商入驻
    supplierSettlementUrl() {
      let url = '/login.html#/supplierSettlement';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
  },
  created() {

  },
  methods: {}
}
</script>

<style lang="less" scoped>
.supplierEntryGuidelines_box {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  background-color: #F0F2F5;

  .title_box {
    text-align: center;

    .title {
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }

    .text {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      opacity: 0.6;
    }
  }

  .header_box {
    background-color: #fff;

    .header_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
      height: 64px;
      margin: 0 auto;

      .title {
        font-size: 17px;
        font-family: PingFangSC-Medium;
        font-weight: bold;
        color: #333333;
        margin-left: 33px;
        position: relative;
        top: 4px;
      }
    }

    .immediateEntry_btn {
      min-width: 110px;
      padding: 0 15px;
      height: 34px;
      border: 1px solid #FF5310;
      border-radius: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FF5310;
    }
  }

  .supplierEntryGuidelines_content {
    .supplierEntryGuidelines_banner {
      width: 100%;
      background: #FA773A;

      .supplierEntryGuidelines_banner_cneter {
        width: 1200px;
        min-height: 210px;
        margin: 0 auto;
        background: url('../../../../assets/images/supplierEntryGuidelines_banner.png');

        .title {
          font-size: 38px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #FFFFFF;
          padding: 30px 0 5px;
        }

        .text {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 28px;
          max-width: 870px;
          padding: 30px 0;
        }
      }
    }

    .ourAdvantages_box {
      width: 100%;
      height: 400px;
      padding: 60px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .ourAdvantages_center {
        display: flex;
        align-items: flex-start;
        margin-top: 30px;

        .ourAdvantages_center_item {
          width: 182px;
          margin: 0 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;

          .icon_title {
            width: 80px;
            height: 80px;
            background: #FFFFFF;
            border: 2px solid #FF5310;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 38px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #FD6930;
          }

          .set_icon_title {
            min-width: 80px;
            height: 60px;
            padding: 0 20px;
            background: #fff;
            border: 2px solid #ff5310;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: 700;
            color: #fd6930;
          }

          .title {
            margin: 20px 0 10px 0;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
          }

          .text {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 20px;
            opacity: 0.6;
          }
        }
      }
    }

    .categoriesInvestment_box {
      height: 466px;
      background-color: #fff;
      padding: 60px 0 80px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .categoriesInvestment_center {
        margin-top: 40px;
        display: flex;
        align-items: center;
      }

      .categoriesInvestment_center_item {
        margin: 0 20px;
        width: 200px;
        height: 200px;
        position: relative;

        .bottom_box {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 36px;
          background: #FF5310;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

    }

    .qualificationRequirement_box {
      width: 100%;
      padding: 60px 0 70px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .qualificationRequirement_center {

        .title {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          margin-bottom: 10px;
        }

        .table_box {
          border-collapse: collapse;
          height: 390px;

          td, th {
            border: 1px solid #DDDFE3;
            padding: 8px;
            border-radius: 6px;
            overflow: hidden;
            background-color: #fff;
          }

          th {
            background-color: #FDF2ED;
            font-weight: bold;
            color: #000;

            &:nth-child(1) {
              width: 135px;
            }

            &:nth-child(2) {
              width: 420px;
            }
          }

          td {
            padding: 20px 15px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
        }
      }
    }

    .entryProcess_box {
      width: 100%;
      padding: 60px 0 90px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #fff;

      .entryProcess_center {
        margin-top: 50px;
        display: flex;
        align-items: flex-start;

        .entryProcess_center_item {
          width: 200px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-right: 60px;
          position: relative;

          .iconItem {
            width: 36px;
            height: 36px;
            background: #FF5200;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #FFFFFF;
          }

          .title {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            margin: 15px 0 10px 0;
          }

          .text {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }

          &:after {
            content: ' ';
            height: 1px;
            width: 100%;
            background-color: #DDDFE3;
            position: absolute;
            top: 20px;
            left: 130px;
          }

          &:last-child {
            margin-right: 0;

            &:after {
              content: ' ';
              height: initial;
              width: initial;
              background-color: transparent;
            }
          }
        }
      }
    }

    .footer_banner {
      width: 100%;
      background-color: #121C36;
      height: 300px;

      .footer_banner_img {
        margin: 0 auto;
        max-width: 1440px;
        height: 300px;
        background: url('../../../../assets/images/supplierEntryGuidelines_footer.png');
        padding: 54px 0 47px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .title_1 {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #FFFFFF;
        }

        .title_2 {
          font-size: 36px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #FFFFFF;
          margin: 25px 0 30px 0;
        }

        .footer_banner_btn {
          margin: 0 auto;
          width: 300px;
          height: 60px;
          background: #FF5310;
          border-radius: 6px;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>